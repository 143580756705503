import React, { useEffect } from 'react';
import { message } from 'antd';
import { Provider } from 'react-redux';
import { BrowserRouter  } from 'react-router-dom'
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import history from './history'
import Layouts from './layouts'
import { THEME_CONFIG } from './configs/AppConfig';
import './lang'
//import mockServer from './mock'

const themes = {
  dark: `${process.env.REACT_APP_PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.REACT_APP_PUBLIC_URL}/css/light-theme.css`,
};

const environment = process.env.NODE_ENV
/*
if (environment !== 'production') {
	mockServer({ environment })
}*/

function App() {

  useEffect(() => {
    const handleOffline = () => {
      message.error('Internet connection lost');
    };

    const handleOnline = () => {
      message.success('Internet connection restored');
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider 
            themeMap={themes} 
            defaultTheme={THEME_CONFIG.currentTheme} 
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>  
      </Provider>
    </div>
  );
}

export default App;
