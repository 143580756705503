import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GiveBackCodesService from 'services/giveback/GivebacksCodesService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/GiveBack/codes/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1, 
	per_page:10,
	last_deleted: null,
	editData: null
}

export const addCode = createAsyncThunk('givebackcodes/addgivebackcode',async (data, { rejectWithValue }) => {
	try {
		const response = await GiveBackCodesService.addgivebackcode(data)
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAll = createAsyncThunk('givebackcodes/getgivebackcodes',async (data, { rejectWithValue }) => {
	const {per_page,current_page,query} = data;
	try {
		const response = await GiveBackCodesService.getgivebackcodes(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOne = createAsyncThunk('givebackcodes/getonegivebackcode',async (codeId, { rejectWithValue }) => {
	try {
		const response = await GiveBackCodesService.getonegivebackcode(codeId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOne = createAsyncThunk('givebackcodes/updategivebackcode',async ({data,codeId}, { rejectWithValue }) => {
	try {
		const response = await GiveBackCodesService.updategivebackcode(data,codeId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOne = createAsyncThunk('givebackcodes/deletegivebackcode',async (codeId, { rejectWithValue }) => {
	
	try {
		const response = await GiveBackCodesService.deletegivebackcode(codeId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const GiveBackCodesSlice = createSlice({
	name: 'givebackcodes',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditData: (state) => {
			state.editData = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addCode.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addCode.fulfilled, (state, action) => {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})
			.addCase(addCode.rejected, (state, action) => {
				state.loading = false
			})

			//GET USERS LIST
			.addCase(getAll.pending, (state) => {
				state.editData = null

				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage

				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				
			})
			.addCase(getAll.rejected, (state, action) => {
				state.loading = false
			})


			//GET ONE USER
			.addCase(getOne.pending, (state) => {
				state.loading = true
			})
			.addCase(getOne.fulfilled, (state, action) => {
				state.loading = false
				state.editData = action.payload.data
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(getOne.rejected, (state, action) => {	
				state.loading = false
			})


			//EDIT USER
			//GET ONE USER
			.addCase(updateOne.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOne.fulfilled, (state, action) => {
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(updateOne.rejected, (state, action) => {
				state.loading = false
			})


			//DELETE USER
			.addCase(deleteOne.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "User Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOne.fulfilled, (state, action) => {
				
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				});
				state.last_deleted = action.payload
			})
			.addCase(deleteOne.rejected, (state, action) => {
				
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData
} = GiveBackCodesSlice.actions

export default GiveBackCodesSlice.reducer