import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BusinessStoreService from 'services/business/BusinessStoreService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/business/locations/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	last_deleted: null,
	editStoreData: null
}

export const addStore = createAsyncThunk('businessstores/addbusinessstore',async (data, { rejectWithValue }) => {
	try {
		const response = await BusinessStoreService.addstore(data)
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAll = createAsyncThunk('businessstores/getbusinessstores',async (data, { rejectWithValue }) => {

	try {
		const response = await BusinessStoreService.getstores(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOneStore = createAsyncThunk('businessstores/getonebusinessstore',async (userId, { rejectWithValue }) => {
	try {
		const response = await BusinessStoreService.getonestore(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOne = createAsyncThunk('businessstores/updatebusinessstore',async ({data,storeId}, { rejectWithValue }) => {
	try {
		const response = await BusinessStoreService.updatestore(data,storeId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOne = createAsyncThunk('businessstores/deletebusinessstore',async (userId, { rejectWithValue }) => {
	
	try {
		const response = await BusinessStoreService.deletestore(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const businessStoreSlice = createSlice({
	name: 'businessstores',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditData: (state) => {
			state.editStoreData = null
		}
		,
		resetPaginantion: (state) => {
			state.pagination = 1
			state.current_page=1
			state.per_page=10
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addStore.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addStore.fulfilled, (state, action) => {
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.loading = false
				state.editStoreData = null
			})
			.addCase(addStore.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET USERS SHOP
			.addCase(getAll.pending, (state) => {
				state.editStoreData = null
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage

				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				
			})
			.addCase(getAll.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//GET ONE 
			.addCase(getOneStore.pending, (state) => {
				state.loading = true
			})
			.addCase(getOneStore.fulfilled, (state, action) => {
				state.loading = false
				state.editStoreData = action.payload.data
				console.log('action.payload.data',action.payload.data)
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
					
				}
			})
			.addCase(getOneStore.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//EDIT 
			//GET ONE 
			.addCase(updateOne.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOne.fulfilled, (state, action) => {
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				  state.editStoreData = null
				}
			})
			.addCase(updateOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//DELETE USER
			.addCase(deleteOne.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "Business Store Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOne.fulfilled, (state, action) => {
				
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.last_deleted = action.payload
			})
			.addCase(deleteOne.rejected, (state, action) => {
				
				message.error({
					content: action.payload.message,
					duration: 2,
				});
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData,
	resetPaginantion
} = businessStoreSlice.actions

export default businessStoreSlice.reducer