import fetch from 'auth/FetchInterceptor'

const EventListingService = {}
  

  EventListingService.geteventlistings = function (data) {

    let url = `/api/admin/get-events?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
}


EventListingService.getoneeventlisting = function (id) {
	return fetch({
		url: '/api/admin/get-event/'+id,
		method: 'get'
	})
}

EventListingService.addeventlisting = function (data) {
	return fetch({
		url: '/api/admin/events/add-events',
		method: 'post',
		data: data,
	})
}

EventListingService.updateeventlisting = function (data,eventId) {
	
	return fetch({
		url: '/api/admin/edit-events/'+eventId,
		method: 'patch',
		data: data,
	})
}

EventListingService.deleteeventlisting = function (id) {
	return fetch({
		url: '/api/admin/delete-events/'+id,
		method: 'delete'
	})
}

export default EventListingService;