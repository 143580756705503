import fetch from 'auth/FetchInterceptor'

const EventTicketsRedeemService = {}

  EventTicketsRedeemService.getticketredeems = function (data) {
    let url = `/api/admin/get-ticket-redeems?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
} 

EventTicketsRedeemService.getoneticketredeem = function (id) {
	return fetch({
		url: '/api/admin/get-ticket-redeem/'+id,
		method: 'get'
	})
}

EventTicketsRedeemService.addticketredeem = function (data) {
	return fetch({
		url: '/api/admin/ticket/add-ticket-redeems',
		method: 'post',
		data: data,
	})
}

EventTicketsRedeemService.updateticketredeem = function (data,Id) {
	return fetch({
		url: '/api/admin/edit-ticket-redeems/'+Id,
		method: 'patch',
		data: data,
	})
}

EventTicketsRedeemService.deleteticketredeem = function (id) {
	return fetch({
		url: '/api/admin/delete-ticket-redeems/'+id,
		method: 'delete'
	})
}

export default EventTicketsRedeemService;