import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BusinessListingService from 'services/business/BusinessListingService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/business/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	last_deleted: null,
	last_edited: null,
	editData: null
}

export const addOne = createAsyncThunk('businesslistings/addbusinesslisting',async (data, { rejectWithValue }) => {
	try {
		const response = await BusinessListingService.addbusinesslisting(data)

		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAll = createAsyncThunk('businesslistings/getbusinesslistings',async (data, { rejectWithValue }) => {
	try {
		const response = await BusinessListingService.getbusinesslistings(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOne = createAsyncThunk('businesslistings/getonebusinesslisting',async (userId, { rejectWithValue }) => {
	try {
		const response = await BusinessListingService.getonebusinesslisting(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOne = createAsyncThunk('businesslistings/updatebusinesslisting',async ({data,userId}, { rejectWithValue }) => {
	try {
		const response = await BusinessListingService.updatebusinesslisting(data,userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOne = createAsyncThunk('businesslistings/deletebusinesslisting',async (userId, { rejectWithValue }) => {
	
	try {
		const response = await BusinessListingService.deletebusinesslisting(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const businessListingSlice = createSlice({
	name: 'businesslistings',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditData: (state) => {
			state.editData = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addOne.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addOne.fulfilled, (state, action) => {
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				  
				  state.last_edited = action.payload.data
				  state.editData = null
				}
				state.loading = false
			})
			.addCase(addOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET LIST
			.addCase(getAll.pending, (state) => {
				state.editData = null
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage
				state.editData = null
				
			})
			.addCase(getAll.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//GET ONE
			.addCase(getOne.pending, (state) => {
				state.loading = true
			})
			.addCase(getOne.fulfilled, (state, action) => {
				state.loading = false
				state.editData = action.payload.data
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} 
			})
			.addCase(getOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//EDIT 
			.addCase(updateOne.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOne.fulfilled, (state, action) => {
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				  state.editData = null
				}
			})
			.addCase(updateOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//DELETE 
			.addCase(deleteOne.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "Business Listing Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOne.fulfilled, (state, action) => {
				
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.last_deleted = action.payload
			})
			.addCase(deleteOne.rejected, (state, action) => {
				
				message.error({
					content: action.payload.message,
					duration: 2,
				});
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData
} = businessListingSlice.actions

export default businessListingSlice.reducer