import fetch from 'auth/FetchInterceptor'

const BusinessCategoriesService = {}
  

  BusinessCategoriesService.getbusinesscategories = function (data) {

    let url = `/api/admin/get-business-categories?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
} 


BusinessCategoriesService.getonebusinesscategory = function (id) {
	return fetch({
		url: '/api/admin/get-business-categorie/'+id,
		method: 'get'
	})
}

BusinessCategoriesService.addbusinesscategory = function (data) {
	return fetch({
		url: '/api/admin/business/add-business-categories',
		method: 'post',
		data: data,
	})
}

BusinessCategoriesService.updatebusinesscategory = function (data,Id) {
	
	return fetch({
		url: '/api/admin/edit-business-categories/'+Id,
		method: 'patch',
		data: data,
	})
}

BusinessCategoriesService.deletebusinesscategory = function (id) {
	return fetch({
		url: '/api/admin/delete-business-categories/'+id,
		method: 'delete'
	})
}

export default BusinessCategoriesService;