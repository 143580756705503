import fetch from 'auth/FetchInterceptor'

const WalletsService = {}
  

WalletsService.getwallets = function (data) {

    let url = `/api/admin/get-wallet-recharges/?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;

    return fetch({
        url: url,
        method: 'get'
    });
}

export default WalletsService;