import fetch from 'auth/FetchInterceptor'

const EventVendorService = {}
  

  EventVendorService.getvendors = function (data) {

    let url = `/api/admin/get-vendors?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
}


EventVendorService.getonevendor = function (id) {
	return fetch({
		url: '/api/admin/get-vendor/'+id,
		method: 'get'
	})
}

EventVendorService.addvendor = function (data) {
	return fetch({
		url: '/api/admin/vendors/add-vendors/',
		method: 'post',
		data: data,
	})
}

EventVendorService.updatevendor = function (data,vendorId) {
	
	return fetch({
		url: '/api/admin/edit-vendors/'+vendorId,
		method: 'patch',
		data: data,
	})
}

EventVendorService.deletevendor = function (id) {
	return fetch({
		url: '/api/admin/delete-vendors/'+id,
		method: 'delete'
	})
}

export default EventVendorService; 