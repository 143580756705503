import fetch from 'auth/FetchInterceptor'

const GivebackCodesService = {}
  

  GivebackCodesService.getgivebackcodes = function (data) {

    let url = `/api/admin/get-code-entered?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
}

GivebackCodesService.getonegivebackcode = function (id) {
	return fetch({
		url: '/api/admin/get-code/'+id,
		method: 'get'
	})
}

GivebackCodesService.addgivebackcode = function (data) {
	return fetch({
		url: '/api/admin/code/add-code-entered',
		method: 'post',
		data: data,
	})
}

GivebackCodesService.updategivebackcode = function (data,Id) {
	
	return fetch({
		url: '/api/admin/edit-code-entered/'+Id,
		method: 'patch',
		data: data,
	})
}

GivebackCodesService.deletegivebackcode = function (id) {
	return fetch({
		url: '/api/admin/delete-code-entered/'+id,
		method: 'delete'
	})
}

export default GivebackCodesService;