import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SalesService from 'services/SalesService';
import { message } from 'antd';

export const initialState = {
	me: null,
	loading: false,
	redirect: '/dashboards/sales/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	user_role: 'sale',
	last_deleted: null,
	editData: null,
	SalesId: null
}

export const addOne = createAsyncThunk('sales/addone',async (data, { rejectWithValue }) => {
	try {
		const response = await SalesService.addone(data)
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAll = createAsyncThunk('sales/getall',async (data, { rejectWithValue }) => {
	const {per_page,current_page,query} = data;
	try {
		const response = await SalesService.getall(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getSalesId = createAsyncThunk('sales/getsalesid',async (data, { rejectWithValue }) => {
	try {
		const response = await SalesService.getsalesid(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOne = createAsyncThunk('sales/getone',async (saleId, { rejectWithValue }) => {
	try {
		const response = await SalesService.getone(saleId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOne = createAsyncThunk('sales/updateone',async ({data,saleId}, { rejectWithValue }) => {
	try {
		const response = await SalesService.updateone(data,saleId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOne = createAsyncThunk('sales/deleteone',async (saleId, { rejectWithValue }) => {
	
	try {
		const response = await SalesService.deleteone(saleId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const salesSlice = createSlice({
	name: 'sales',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditSale: (state) => {
			state.editData = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addOne.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addOne.fulfilled, (state, action) => {
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.loading = false
				state.redirect = '/dashboards/sales'
			})

			.addCase(addOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET USERS LIST
			.addCase(getAll.pending, (state) => {
				state.editData = null
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage

				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				
			})
			.addCase(getAll.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			
			//GET getSalesId 
			.addCase(getSalesId.pending, (state) => {
				state.SalesId = null
				state.loading = true
			})
			.addCase(getSalesId.fulfilled, (state, action) => {
				state.loading = false
				state.SalesId = action.payload.data[0]._id
			})
			.addCase(getSalesId.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//GET ONE USER
			.addCase(getOne.pending, (state) => {
				state.loading = true
			})
			.addCase(getOne.fulfilled, (state, action) => {
				state.loading = false
				state.editData = action.payload.data
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(getOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//EDIT USER
			//GET ONE USER
			.addCase(updateOne.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOne.fulfilled, (state, action) => {
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(updateOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//DELETE USER
			.addCase(deleteOne.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "Sale Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOne.fulfilled, (state, action) => {
				
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				});
				state.last_deleted = action.payload
			})
			.addCase(deleteOne.rejected, (state, action) => {
				
				message.error({
					content: action.payload.message,
					duration: 2,
				});
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData
} = salesSlice.actions

export default salesSlice.reducer