import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import WalletsService from 'services/WalletsService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	queryData: '',
	wallets: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10
}

export const getWallets = createAsyncThunk('wallet/getwallets',async (data, { rejectWithValue }) => {
	try {
		const response = await WalletsService.getwallets(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const walletSlice = createSlice({
	name: 'wallets',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			//GET WALLETS LIST
			.addCase(getWallets.pending, (state) => {
				state.editUser = null
				state.loading = true
			})
			.addCase(getWallets.fulfilled, (state, action) => {
				state.loading = false
				state.wallets = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage

				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				
			})
			.addCase(getWallets.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData
} = walletSlice.actions

export default walletSlice.reducer