import fetch from 'auth/FetchInterceptor'

const RedeemsService = {}


  RedeemsService.getredeems = function (data) {
    let url = `/api/admin/get-redeemed-deals/?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;

    return fetch({
        url: url,
        method: 'get'
    });
}


RedeemsService.getoneredeem = function (id) {
	return fetch({
		url: '/api/admin/get-redeemed/'+id,
		method: 'get'
	})
}

RedeemsService.deleteoneredeem = function (id) {
	return fetch({
		url: '/api/admin/delete-redeemed-deals/'+id,
		method: 'delete'
	})
}


export default RedeemsService;