import fetch from 'auth/FetchInterceptor'

const BusinessDealsService = {}
  

  BusinessDealsService.getbusinessdeals = function (data) {

    let url = `/api/admin/get-deals?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
}

BusinessDealsService.getonebusinessdeal = function (id) {
	return fetch({
		url: '/api/admin/get-one-deals/'+id,
		method: 'get'
	})
}

BusinessDealsService.addbusinessdeal = function (data) {
	
	return fetch({
		url: '/api/admin/deals/add-deals',
		method: 'post',
		data: data,
	})

}

BusinessDealsService.updatebusinessdeal = function (data,Id) {
	
	return fetch({
		url: '/api/admin/edit-deals/'+Id,
		method: 'patch',
		data: data,
	})
}

BusinessDealsService.deletebusinessdeal = function (id) {
	return fetch({
		url: '/api/admin/delete-deals/'+id,
		method: 'delete'
	})
}

export default BusinessDealsService;