import fetch from 'auth/FetchInterceptor'

const EventTicketsService = {}

  EventTicketsService.getticketsales = function (data) {
    let url = `/api/admin/get-ticket-sales?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
} 

EventTicketsService.getoneticketsale = function (id) {
	return fetch({
		url: '/api/admin/get-ticket-sale/'+id,
		method: 'get'
	})
}

EventTicketsService.addticketsale = function (data) {
	return fetch({
		url: '/api/admin/ticket/add-ticket-sales',
		method: 'post',
		data: data,
	})
}

EventTicketsService.updateticketsale = function (data,Id) {
	return fetch({
		url: '/api/admin/edit-ticket-sales/'+Id,
		method: 'patch',
		data: data,
	})
}

EventTicketsService.deleteticketsale = function (id) {
	return fetch({
		url: '/api/admin/delete-ticket-sales/'+id,
		method: 'delete'
	})
}

export default EventTicketsService;