import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EventTicketsRedeemService from 'services/events/EventsTicketsRedeemService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/events/ticket/redeems/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	last_deleted: null,
	last_edited: null,
	editDataTicket: null,
}

export const addOneTicket = createAsyncThunk('ticketredeems/addticketredeem',async (data, { rejectWithValue }) => {
	try {
		const response = await EventTicketsRedeemService.addticketredeem(data)

		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAllTickets = createAsyncThunk('ticketredeems/getticketredeems',async (data, { rejectWithValue }) => {
	try {
		const response = await EventTicketsRedeemService.getticketredeems(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOneTicket = createAsyncThunk('ticketredeems/getoneticketredeem',async (ticketid, { rejectWithValue }) => {
	try {
		const response = await EventTicketsRedeemService.getoneticketredeem(ticketid)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOneTicket = createAsyncThunk('ticketredeems/updateticketredeem',async ({data,ticketid}, { rejectWithValue }) => {
	try {
		const response = await EventTicketsRedeemService.updateticketredeem(data,ticketid)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOneTicket = createAsyncThunk('ticketredeems/deleteticketredeem',async (ticketid, { rejectWithValue }) => {
	
	try {
		const response = await EventTicketsRedeemService.deleteticketredeem(ticketid)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const ticketsRedeemSlice = createSlice({
	name: 'ticketredeems',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditData: (state) => {
			state.editDataTicket = null
		},
		resetPaginantion: (state) => {
			state.pagination = 1
			state.current_page=1
			state.per_page=10
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addOneTicket.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addOneTicket.fulfilled, (state, action) => {
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				 
				  state.last_edited = action.payload.data
				  
				}
				state.loading = false
			})
			.addCase(addOneTicket.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET LIST
			.addCase(getAllTickets.pending, (state) => {
				state.editDataTicket = null
				state.loading = true
			})
			.addCase(getAllTickets.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage
/*
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				*/
			})
			.addCase(getAllTickets.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//GET ONE
			.addCase(getOneTicket.pending, (state) => {
				state.loading = true
			})
			.addCase(getOneTicket.fulfilled, (state, action) => {
				state.loading = false
				state.editDataTicket = action.payload.data
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				}
			})
			.addCase(getOneTicket.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//EDIT 
			.addCase(updateOneTicket.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOneTicket.fulfilled, (state, action) => {
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });

				}
			})
			.addCase(updateOneTicket.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//DELETE 
			.addCase(deleteOneTicket.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "Ticket Listing Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOneTicket.fulfilled, (state, action) => {
				
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.last_deleted = action.payload
			})
			.addCase(deleteOneTicket.rejected, (state, action) => {
				
				message.error({
					content: action.payload.message,
					duration: 2,
				});
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData,
	resetPaginantion
} = ticketsRedeemSlice.actions

export default ticketsRedeemSlice.reducer