import fetch from 'auth/FetchInterceptor'

const BusinessListingService = {}
  

  BusinessListingService.getbusinesslistings = function (data) {

    let url = `/api/admin/get-business-listing?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;

    return fetch({
        url: url,
        method: 'get'
    });
}


BusinessListingService.getonebusinesslisting = function (id) {
	return fetch({
		url: '/api/admin/get-one-business-listing/'+id,
		method: 'get'
	})
}

BusinessListingService.addbusinesslisting = function (data) {
	return fetch({
		url: '/api/admin/business/add-business-listing/',
		method: 'post',
		data: data,
	})
}

BusinessListingService.updatebusinesslisting = function (data,userId) {
	
	return fetch({
		url: '/api/admin/edit-business-listing/'+userId,
		method: 'patch',
		data: data,
	})
}

BusinessListingService.deletebusinesslisting = function (id) {
	return fetch({
		url: '/api/admin/delete-business-listing/'+id,
		method: 'delete'
	})
}

export default BusinessListingService;