import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EventListingService from 'services/events/EventsListingService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/events/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	last_deleted: null,
	last_edited: null,
	editDataEvent: null,
	eventHost: null
}

export const addOneEvent = createAsyncThunk('eventlistings/addeventlisting',async (data, { rejectWithValue }) => {
	try {
		const response = await EventListingService.addeventlisting(data)

		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAllEvents = createAsyncThunk('eventlistings/geteventlistings',async (data, { rejectWithValue }) => {
	try {
		const response = await EventListingService.geteventlistings(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOneEvent = createAsyncThunk('eventlistings/getoneeventlisting',async (eventId, { rejectWithValue }) => {
	try {
		const response = await EventListingService.getoneeventlisting(eventId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOneEvent = createAsyncThunk('eventlistings/updateeventlisting',async ({data,eventId}, { rejectWithValue }) => {
	try {
		const response = await EventListingService.updateeventlisting(data,eventId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOneEvent = createAsyncThunk('eventlistings/deleteeventlisting',async (eventId, { rejectWithValue }) => {
	
	try {
		const response = await EventListingService.deleteeventlisting(eventId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const eventListingSlice = createSlice({
	name: 'eventlistings',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditData: (state) => {
			state.editDataEvent = null
		},
		setEventHost : (state, action) => {
			state.eventHost = action.payload
		},
		resetPaginantion: (state) => {
			state.pagination = 1
			state.current_page=1
			state.per_page=10
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addOneEvent.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addOneEvent.fulfilled, (state, action) => {
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				 
				  state.last_edited = action.payload.data
				  
				}
				state.loading = false
				state.eventHost = null
			})
			.addCase(addOneEvent.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET LIST
			.addCase(getAllEvents.pending, (state) => {
				state.editDataEvent = null
				state.loading = true
			})
			.addCase(getAllEvents.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage
/*
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				*/
			})
			.addCase(getAllEvents.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//GET ONE
			.addCase(getOneEvent.pending, (state) => {
				state.loading = true
			})
			.addCase(getOneEvent.fulfilled, (state, action) => {
				state.loading = false
				state.editDataEvent = action.payload.data
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				}
			})
			.addCase(getOneEvent.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//EDIT 
			.addCase(updateOneEvent.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOneEvent.fulfilled, (state, action) => {
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });

				}
			})
			.addCase(updateOneEvent.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//DELETE 
			.addCase(deleteOneEvent.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "Event Listing Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOneEvent.fulfilled, (state, action) => {
				
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.last_deleted = action.payload
			})
			.addCase(deleteOneEvent.rejected, (state, action) => {
				
				message.error({
					content: action.payload.message,
					duration: 2,
				});
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData,
	setEventHost,
	resetPaginantion
} = eventListingSlice.actions

export default eventListingSlice.reducer