import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EventVendorService from 'services/events/EventsVendorService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/events/vendors',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	last_deleted: null,
	editVendorData: null
}

export const addVendor = createAsyncThunk('eventvendors/addeventvendor',async (data, { rejectWithValue }) => {
	try {
		const response = await EventVendorService.addvendor(data)
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAll = createAsyncThunk('eventvendors/geteventvendors',async (data, { rejectWithValue }) => {

	try {
		const response = await EventVendorService.getvendors(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOneVendor = createAsyncThunk('eventvendors/getoneeventvendor',async (userId, { rejectWithValue }) => {
	try {
		const response = await EventVendorService.getonevendor(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOne = createAsyncThunk('eventvendors/updateeventvendor',async ({data,vendorId}, { rejectWithValue }) => {
	try {
		const response = await EventVendorService.updatevendor(data,vendorId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOne = createAsyncThunk('eventvendors/deleteeventvendor',async (userId, { rejectWithValue }) => {
	
	try {
		const response = await EventVendorService.deletevendor(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const eventVendorSlice = createSlice({
	name: 'eventvendors',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditData: (state) => {
			state.editVendorData = null
		}
		,
		resetPaginantion: (state) => {
			state.pagination = 1
			state.current_page=1
			state.per_page=10
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addVendor.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addVendor.fulfilled, (state, action) => {
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.loading = false
				state.editVendorData = null
			})
			.addCase(addVendor.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET USERS SHOP
			.addCase(getAll.pending, (state) => {
				state.editVendorData = null
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage

				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				
			})
			.addCase(getAll.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//GET ONE 
			.addCase(getOneVendor.pending, (state) => {
				state.loading = true
			})
			.addCase(getOneVendor.fulfilled, (state, action) => {
				state.loading = false
				state.editVendorData = action.payload.data
				console.log('action.payload.data',action.payload.data)
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
					
				}
			})
			.addCase(getOneVendor.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//EDIT 
			//GET ONE 
			.addCase(updateOne.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOne.fulfilled, (state, action) => {
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				  state.editVendorData = null
				}
			})
			.addCase(updateOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//DELETE USER
			.addCase(deleteOne.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "Vendor Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOne.fulfilled, (state, action) => {
				
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.last_deleted = action.payload
			})
			.addCase(deleteOne.rejected, (state, action) => {
				
				message.error({
					content: action.payload.message,
					duration: 2,
				});
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData,
	resetPaginantion
} = eventVendorSlice.actions

export default eventVendorSlice.reducer