import fetch from 'auth/FetchInterceptor'

const EventVoteService = {}
  

  EventVoteService.geteventvotes = function (data) {

    let url = `/api/admin/get-vote?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
}


EventVoteService.getoneeventvote = function (id) {
	return fetch({
		url: '/api/admin/get-one-vote/'+id,
		method: 'get'
	})
}

EventVoteService.addeventvote = function (data) {
	return fetch({
		url: '/api/admin/add-vote',
		method: 'post',
		data: data,
	})
}

EventVoteService.updateeventvote = function (data,pollId) {
	console.log('service',data,pollId)
	return fetch({
		url: '/api/admin/edit-vote/'+pollId,
		method: 'patch',
		data: data,
	})
}

EventVoteService.deleteeventvote = function (id) {
	return fetch({
		url: '/api/admin/delete-vote/'+id,
		method: 'delete'
	})
}

export default EventVoteService;