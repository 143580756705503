import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import EventVoteService from 'services/events/EventsVoteService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/events/polls/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	last_deleted: null,
	last_edited: null,
	editDataVote: null,
	eventHost: null
}

export const addOneVote = createAsyncThunk('eventvotes/addeventvote',async (data, { rejectWithValue }) => {
	try {
		const response = await EventVoteService.addeventvote(data)

		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAllVotes = createAsyncThunk('eventvotes/geteventvotes',async (data, { rejectWithValue }) => {
	try {
		const response = await EventVoteService.geteventvotes(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOneVote = createAsyncThunk('eventvotes/getoneeventvote',async (voteId, { rejectWithValue }) => {
	try {
		const response = await EventVoteService.getoneeventvote(voteId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOneVote = createAsyncThunk('eventvotes/updateeventvote',async ({data,pollId}, { rejectWithValue }) => {
	console.log('slice',{data,pollId})
	try {
		const response = await EventVoteService.updateeventvote(data,pollId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOneVote = createAsyncThunk('eventvotes/deleteeventvote',async (voteId, { rejectWithValue }) => {
	
	try {
		const response = await EventVoteService.deleteeventvote(voteId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const eventVoteSlice = createSlice({
	name: 'eventvotes',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditData: (state) => {
			state.editDataVote = null
		},
		setVoteHost : (state, action) => {
			state.eventHost = action.payload
		},
		resetPaginantion: (state) => {
			state.pagination = 1
			state.current_page=1
			state.per_page=10
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addOneVote.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addOneVote.fulfilled, (state, action) => {
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				 
				  state.last_edited = action.payload.data
				 
				}
				state.loading = false
				state.eventHost = null
			})
			.addCase(addOneVote.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET LIST
			.addCase(getAllVotes.pending, (state) => {
				state.editDataVote = null
				state.loading = true
			})
			.addCase(getAllVotes.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage
/*
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				*/
			})
			.addCase(getAllVotes.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//GET ONE
			.addCase(getOneVote.pending, (state) => {
				state.loading = true
			})
			.addCase(getOneVote.fulfilled, (state, action) => {
				state.loading = false
				state.editDataVote = action.payload.data
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				}
			})
			.addCase(getOneVote.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//EDIT 
			.addCase(updateOneVote.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOneVote.fulfilled, (state, action) => {
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });

				}
			})
			.addCase(updateOneVote.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//DELETE 
			.addCase(deleteOneVote.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "Poll Vote Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOneVote.fulfilled, (state, action) => {
				
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.last_deleted = action.payload
			})
			.addCase(deleteOneVote.rejected, (state, action) => {
				
				message.error({
					content: action.payload.message,
					duration: 2,
				});
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData,
	setVoteHost,
	resetPaginantion
} = eventVoteSlice.actions

export default eventVoteSlice.reducer