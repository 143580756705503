import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { AUTH_TOKEN,LOGGED_USER_ID,LOGGED_USER_ROLE } from 'constants/AuthConstant';
import FirebaseService from 'services/FirebaseService';
import AuthService from 'services/AuthService';

export const initialState = {
	loading: false,
	message: '',
	showMessage: false,
	redirect: '',
	token: localStorage.getItem(AUTH_TOKEN) || null,
	myId : localStorage.getItem(LOGGED_USER_ID) || null,
	myRole : localStorage.getItem(LOGGED_USER_ROLE) || null
}

export const signIn = createAsyncThunk('auth/login',async (data, { rejectWithValue }) => {
	const { email, password } = data
	const lowercaseEmail = email.toLowerCase();
	try {
		const response = await AuthService.login({ ...data, email: lowercaseEmail });

		
		const token = response.token;
		const myId = response.id;
		const myRole = response.role;
		
		localStorage.setItem(AUTH_TOKEN, token);
		localStorage.setItem(LOGGED_USER_ID, myId);
		localStorage.setItem(LOGGED_USER_ROLE, myRole);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error !!!')
	}
})
  


export const signUp = createAsyncThunk('auth/register',async (data, { rejectWithValue }) => {
	const { email, password } = data
	try {
		const response = await AuthService.register({email, password})
		const token = response.data.token;
		localStorage.setItem(AUTH_TOKEN, token);
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const signOut = createAsyncThunk('auth/logout',async () => {
    const response = await AuthService.logout()
	localStorage.removeItem(AUTH_TOKEN);
    return response.data
})


export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authenticated: (state, action) => {
			state.loading = false
			state.redirect = '/'
			state.token = action.payload
		},
		showAuthMessage: (state, action) => {
			state.message = action.payload
			state.showMessage = true
			state.loading = false
		},
		hideAuthMessage: (state) => {
			state.message = ''
			state.showMessage = false
		},
		signOutSuccess: (state) => {
			state.loading = false
			state.token = null
			state.redirect = '/'
		},
		showLoading: (state) => {
			state.loading = true
		},
		signInSuccess: (state, action) => {
			state.loading = false
			state.token = action.payload
		}
	},
	extraReducers: (builder) => {
		builder
			//SIGN IN
			.addCase(signIn.pending, (state) => {
				state.loading = true
			})
			.addCase(signIn.fulfilled, (state, action) => {
				
				state.loading = false
				state.token = action.payload.token
				state.myId = action.payload.id
				//state.message = action.payload.message
				state.showMessage = true
				state.redirect = '/'
				
			})
			.addCase(signIn.rejected, (state, action) => {
				
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})

			//SIGNOUT
			.addCase(signOut.fulfilled, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})
			.addCase(signOut.rejected, (state) => {
				state.loading = false
				state.token = null
				state.redirect = '/'
			})

			
			.addCase(signUp.pending, (state) => {
				state.loading = true
			})
			.addCase(signUp.fulfilled, (state, action) => {
				state.loading = false
				state.redirect = '/'
				state.token = action.payload
			})
			.addCase(signUp.rejected, (state, action) => {
				state.message = action.payload
				state.showMessage = true
				state.loading = false
			})

	},
})

export const { 
	authenticated,
	showAuthMessage,
	hideAuthMessage,
	signOutSuccess,
	showLoading,
	signInSuccess
} = authSlice.actions

export default authSlice.reducer