import fetch from 'auth/FetchInterceptor'

const VendorsCategoriesService = {}
  

  VendorsCategoriesService.getvendorcategories = function (data) {

    let url = `/api/admin/get-vendors-categories?perPage=${data.perPage}&page=${data.page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
} 

VendorsCategoriesService.getonevendorcategory = function (id) {
	return fetch({
		url: '/api/admin/get-vendors-categorie/'+id,
		method: 'get'
	})
}

VendorsCategoriesService.addvendorcategory = function (data) {
	return fetch({
		url: '/api/admin/vendors/add-vendors-categories',
		method: 'post',
		data: data,
	})
}

VendorsCategoriesService.updatevendorcategory = function (data,Id) {
	
	return fetch({
		url: '/api/admin/edit-vendors-categories/'+Id,
		method: 'patch',
		data: data,
	})
}

VendorsCategoriesService.deletevendorcategory = function (id) {
	return fetch({
		url: '/api/admin/delete-vendors-categories/'+id,
		method: 'delete'
	})
}

export default VendorsCategoriesService;