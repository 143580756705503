import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SponsorsService from 'services/events/EventsSponsorsService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/events/sponsors/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1, 
	per_page:10,
	last_deleted: null,
	editData: null
}

export const addOne = createAsyncThunk('sponsors/addsponsor',async (data, { rejectWithValue }) => {
	try {
		const response = await SponsorsService.addsponsor(data)
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAll = createAsyncThunk('sponsors/getsponsors',async (data, { rejectWithValue }) => {
	
	try {
		const response = await SponsorsService.getsponsors(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOne = createAsyncThunk('sponsors/getonesponsor',async (sponsorId, { rejectWithValue }) => {
	try {
		const response = await SponsorsService.getonesponsor(sponsorId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOne = createAsyncThunk('sponsors/updatesponsor',async ({data,sponsorId}, { rejectWithValue }) => {
	try {
		const response = await SponsorsService.updatesponsor(data,sponsorId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOne = createAsyncThunk('sponsors/deletesponsor',async (sponsorId, { rejectWithValue }) => {
	
	try {
		const response = await SponsorsService.deletesponsor(sponsorId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const sponsorsSlice = createSlice({
	name: 'sponsors',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditUser: (state) => {
			state.editData = null
		}
	},
	extraReducers: (builder) => {
		builder

			//ADD sponsor
			.addCase(addOne.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addOne.fulfilled, (state, action) => {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})
			.addCase(addOne.rejected, (state, action) => {
				state.loading = false
			})

			//GET LIST
			.addCase(getAll.pending, (state) => {
				state.editData = null
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage

				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				
			})
			.addCase(getAll.rejected, (state, action) => {
				state.loading = false
			})


			//GET ONE
			.addCase(getOne.pending, (state) => {
				state.loading = true
			})
			.addCase(getOne.fulfilled, (state, action) => {
				state.loading = false
				state.editData = action.payload.data
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(getOne.rejected, (state, action) => {	
				state.loading = false
			})

			//UPDATE
			.addCase(updateOne.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOne.fulfilled, (state, action) => {
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(updateOne.rejected, (state, action) => {
				state.loading = false
			})


			//DELETE
			.addCase(deleteOne.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "User Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOne.fulfilled, (state, action) => {
				
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				});
				state.last_deleted = action.payload
			})
			.addCase(deleteOne.rejected, (state, action) => {
				
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditUser
} = sponsorsSlice.actions

export default sponsorsSlice.reducer