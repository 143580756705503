import fetch from 'auth/FetchInterceptor'

const GivebackListingService = {}
  

  GivebackListingService.getgivebacklistings = function (data) {

    let url = `/api/admin/get-giveback-list?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
}


GivebackListingService.getonegivebacklisting = function (id) {
	return fetch({
		url: '/api/admin/get-one-giveback/'+id,
		method: 'get'
	})
}

GivebackListingService.addgivebacklisting = function (data) {
	return fetch({
		url: '/api/admin/giveback/add-giveback',
		method: 'post',
		data: data,
	})
}

GivebackListingService.updategivebacklisting = function (data,givebackId) {
	
	return fetch({
		url: '/api/admin/edit-giveback/'+givebackId,
		method: 'patch',
		data: data,
	})
}

GivebackListingService.deletegivebacklisting = function (id) {
	return fetch({
		url: '/api/admin/delete-giveback/'+id,
		method: 'delete'
	})
}

GivebackListingService.code = function (data) {
	return fetch({
		url: '/api/admin/edit-giveback-code',
		method: 'patch',
		data: data,
	})
}

export default GivebackListingService;