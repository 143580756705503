import fetch from 'auth/FetchInterceptor'

const SalesService = {}
  
SalesService.addone = function (data) {

    let url = `/api/admin/sales/add-sales`;

    return fetch({
        url: url,
        method: 'post',
		data:data
    });
}


  SalesService.getall = function (data) {

    let url = `/api/admin/get-sales/?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;

    return fetch({
        url: url,
        method: 'get'
    });
}

SalesService.getsalesid = function (data) {

    let url = `/api/admin/get-sales/?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;

    return fetch({
        url: url,
        method: 'get'
    });
}


SalesService.getone = function (id) {
	return fetch({
		url: '/api/admin/one-sales-get/'+id,
		method: 'get'
	})
}

SalesService.updateone = function (data,saleId) {

	return fetch({
		url: '/api/admin/edit-sales/'+saleId,
		method: 'patch',
		data: data,
	})
}

SalesService.deleteone = function (id) {
	return fetch({
		url: '/api/admin/delete-sales/'+id,
		method: 'delete'
	})
}

export default SalesService;