import fetch from 'auth/FetchInterceptor'

const BusinessUsersService = {}
  

  BusinessUsersService.getbusinessusers = function (data) {

    let url = `/api/admin/get-business-user-list?perPage=${data.per_page}&page=${data.current_page}/?${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
}


BusinessUsersService.getonebusinessuser = function (id) {
	return fetch({
		url: '/api/admin/get-business-user/'+id,
		method: 'get'
	})
}

BusinessUsersService.addbusinessuser = function (data) {
	return fetch({
		url: '/api/admin/business/add-business-user-list/',
		method: 'post',
		headers: {'Content-Type': 'application/json'},
		data: data,
	})
}

BusinessUsersService.updatebusinessuser = function (data,userId) {
	
	return fetch({
		url: '/api/admin/edit-business-user-list/'+userId,
		method: 'patch',
		data: data,
	})
}

BusinessUsersService.deletebusinessuser = function (id) {
	
	return fetch({
		url: '/api/admin/delete-business-user-list/'+id,
		method: 'delete'
	})
}

export default BusinessUsersService;