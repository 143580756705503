import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.users',
        path: `${APP_PREFIX_PATH}/dashboards/users`,
        component: React.lazy(() => import('views/app-views/dashboards/users')),
    },
    {
        key: 'dashboard.users.addnew',
        path: `${APP_PREFIX_PATH}/dashboards/users/:mode`,
        component: React.lazy(() => import('views/app-views/dashboards/users/crud')),
    },
    {
        key: 'dashboard.users.edit',
        path: `${APP_PREFIX_PATH}/dashboards/users/:mode/:userid`,
        component: React.lazy(() => import('views/app-views/dashboards/users/crud')),
    },
    {
        key: 'dashboard.redeemed',
        path: `${APP_PREFIX_PATH}/dashboards/redeemed`,
        component: React.lazy(() => import('views/app-views/dashboards/redeemed')),
    },
    {
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        component: React.lazy(() => import('views/app-views/dashboards/sales')),
    },
    {
        key: 'dashboard.sales.addnew',
        path: `${APP_PREFIX_PATH}/dashboards/sales/:mode`,
        component: React.lazy(() => import('views/app-views/dashboards/sales/crud')),
    },
    {
        key: 'dashboard.sales.edit',
        path: `${APP_PREFIX_PATH}/dashboards/sales/:mode/:salesid`,
        component: React.lazy(() => import('views/app-views/dashboards/sales/crud')),
    },
    {
        key: 'dashboard.wallet',
        path: `${APP_PREFIX_PATH}/dashboards/wallet`,
        component: React.lazy(() => import('views/app-views/dashboards/wallet')),
    },
    {
        key: 'listing.business',
        path: `${APP_PREFIX_PATH}/listings/business`,
        component: React.lazy(() => import('views/app-views/listings/business/list')),
    },
    {
        key: 'listing.business.pending',
        path: `${APP_PREFIX_PATH}/listings/business/pending`,
        component: React.lazy(() => import('views/app-views/listings/business/list/')),
    },
    {
        key: 'listing.business.addnew',
        path: `${APP_PREFIX_PATH}/listings/business/:mode`,
        component: React.lazy(() => import('views/app-views/listings/business/list/crud')),
    },
    {
        key: 'listing.business.edit',
        path: `${APP_PREFIX_PATH}/listings/business/:mode/:main_business_id`,
        component: React.lazy(() => import('views/app-views/listings/business/list/crud')),
    },
    {
        key: 'listing.business.users.all',
        path: `${APP_PREFIX_PATH}/listings/business/users`,
        component: React.lazy(() => import('views/app-views/listings/business/users')),
    },
    {
        key: 'listing.business.users.addnew',
        path: `${APP_PREFIX_PATH}/listings/business/users/:mode`,
        component: React.lazy(() => import('views/app-views/listings/business/users/crud')),
    },
    {
        key: 'listing.business.users.edit',
        path: `${APP_PREFIX_PATH}/listings/business/users/:mode/:userid`,
        component: React.lazy(() => import('views/app-views/listings/business/users/crud')),
    },
    {
        key: 'listing.business.users',
        path: `${APP_PREFIX_PATH}/listings/business/relations`,
        component: React.lazy(() => import('views/app-views/listings/business/businessUsersRelations')),
    },
    {
        key: 'listing.business.users.relation.addnew',
        path: `${APP_PREFIX_PATH}/listings/business/relations/:mode`,
        component: React.lazy(() => import('views/app-views/listings/business/businessUsersRelations/crud')),
    },
    {
        key: 'listing.business.users.relation.edit',
        path: `${APP_PREFIX_PATH}/listings/business/relations/:mode/:relationid`,
        component: React.lazy(() => import('views/app-views/listings/business/businessUsersRelations/crud')),
    },
    {
        key: 'listing.business.outlets',
        path: `${APP_PREFIX_PATH}/listings/business/locations`,
        component: React.lazy(() => import('views/app-views/listings/business/stores')),
    },
    {
        key: 'listing.business.outlets.add',
        path: `${APP_PREFIX_PATH}/listings/business/locations/:mode`,
        component: React.lazy(() => import('views/app-views/listings/business/stores/crud')),
    },
    {
        key: 'listing.business.outlets.edit',
        path: `${APP_PREFIX_PATH}/listings/business/locations/:mode/:storeid`,
        component: React.lazy(() => import('views/app-views/listings/business/stores/crud')),
    },
    {
        key: 'listing.business.deals',
        path: `${APP_PREFIX_PATH}/listings/business/deals`,
        component: React.lazy(() => import('views/app-views/listings/business/deals')),
    },
    {
        key: 'listing.business.deals.addnew',
        path: `${APP_PREFIX_PATH}/listings/business/deals/:mode`,
        component: React.lazy(() => import('views/app-views/listings/business/deals/crud')),
    },
    {
        key: 'listing.business.deals.edit',
        path: `${APP_PREFIX_PATH}/listings/business/deals/:mode/:dealid`,
        component: React.lazy(() => import('views/app-views/listings/business/deals/crud')),
    },
    {
        key: 'listing.business.categories',
        path: `${APP_PREFIX_PATH}/listings/business/categories`,
        component: React.lazy(() => import('views/app-views/listings/business/categories')),
    },
    {
        key: 'listing.business.categories',
        path: `${APP_PREFIX_PATH}/listings/business/categories/:mode`,
        component: React.lazy(() => import('views/app-views/listings/business/categories/crud')),
    },
    {
        key: 'listing.business.categories',
        path: `${APP_PREFIX_PATH}/listings/business/categories/:mode/:id`,
        component: React.lazy(() => import('views/app-views/listings/business/categories/crud')),
    },
    {
        key: 'listing.events',
        path: `${APP_PREFIX_PATH}/listings/events`,
        component: React.lazy(() => import('views/app-views/listings/events/list')),
    },
    {
        key: 'listing.events.addnew',
        path: `${APP_PREFIX_PATH}/listings/events/:mode`,
        component: React.lazy(() => import('views/app-views/listings/events/list/crud')),
    },
    {
        key: 'listing.events.edit',
        path: `${APP_PREFIX_PATH}/listings/events/:mode/:eventid`,
        component: React.lazy(() => import('views/app-views/listings/events/list/crud')),
    },
    {
        key: 'listing.ticketsales',
        path: `${APP_PREFIX_PATH}/listings/events/ticket/sales`,
        component: React.lazy(() => import('views/app-views/listings/events/ticketsales')),
    },
    {
        key: 'listing.ticketsales.addnew',
        path: `${APP_PREFIX_PATH}/listings/events/ticket/sales/:mode`,
        component: React.lazy(() => import('views/app-views/listings/events/ticketsales/crud')),
    },
    {
        key: 'listing.ticketsales.edit',
        path: `${APP_PREFIX_PATH}/listings/events/ticket/sales/:mode/:ticketid`,
        component: React.lazy(() => import('views/app-views/listings/events/ticketsales/crud')),
    },
    {
        key: 'listing.events.ticketredeems',
        path: `${APP_PREFIX_PATH}/listings/events/ticket/redeems`,
        component: React.lazy(() => import('views/app-views/listings/events/ticketredeems')),
    },
    {
        key: 'listing.events.ticketredeems.addnew',
        path: `${APP_PREFIX_PATH}/listings/events/ticket/redeems/:mode`,
        component: React.lazy(() => import('views/app-views/listings/events/ticketredeems/crud')),
    },
    {
        key: 'listing.events.ticketredeems.edit',
        path: `${APP_PREFIX_PATH}/listings/events/ticket/redeems/:mode/:ticketredeemid`,
        component: React.lazy(() => import('views/app-views/listings/events/ticketredeems/crud')),
    },
    {
        key: 'listing.events.ticket.sold',
        path: `${APP_PREFIX_PATH}/listings/events/ticket/sold`,
        component: React.lazy(() => import('views/app-views/listings/events/ticketbought')),
    },
    {
        key: 'listing.events.ticket.sold.addnew',
        path: `${APP_PREFIX_PATH}/listings/events/ticket/sold/:mode`,
        component: React.lazy(() => import('views/app-views/listings/events/ticketbought/crud')),
    },
    {
        key: 'listing.events.ticket.sold.edit',
        path: `${APP_PREFIX_PATH}/listings/events/ticket/sold/:mode/:ticketredeemid`,
        component: React.lazy(() => import('views/app-views/listings/events/ticketbought/crud')),
    },
    {
        key: 'listing.events.vendors',
        path: `${APP_PREFIX_PATH}/listings/events/vendors`,
        component: React.lazy(() => import('views/app-views/listings/events/vendors')),
    },
    {
        key: 'listing.business.vendors.add',
        path: `${APP_PREFIX_PATH}/listings/events/vendors/:mode`,
        component: React.lazy(() => import('views/app-views/listings/events/vendors/crud')),
    },
    {
        key: 'listing.business.vendors.edit',
        path: `${APP_PREFIX_PATH}/listings/events/vendors/:mode/:vendorid`,
        component: React.lazy(() => import('views/app-views/listings/events/vendors/crud')),
    },
    {
        key: 'listing.events.vendors.categories',
        path: `${APP_PREFIX_PATH}/listings/events/vendors/categories`,
        component: React.lazy(() => import('views/app-views/listings/events/vendorsCategories/')),
    },
    {
        key: 'listing.events.vendors.categories.add',
        path: `${APP_PREFIX_PATH}/listings/events/vendors/categories/:mode`,
        component: React.lazy(() => import('views/app-views/listings/events/vendorsCategories/crud')),
    },
    {
        key: 'listing.events.vendors.categories.edit',
        path: `${APP_PREFIX_PATH}/listings/events/vendors/categories/:mode/:id`,
        component: React.lazy(() => import('views/app-views/listings/events/vendorsCategories/crud')),
    },
    {
        key: 'listing.events.sponsors',
        path: `${APP_PREFIX_PATH}/listings/events/sponsors`,
        component: React.lazy(() => import('views/app-views/listings/events/sponsors')),
    },{
        key: 'listing.events.sponsors.add',
        path: `${APP_PREFIX_PATH}/listings/events/sponsors/:mode`,
        component: React.lazy(() => import('views/app-views/listings/events/sponsors/crud')),
    },
    {
        key: 'listing.events.sponsors.edit',
        path: `${APP_PREFIX_PATH}/listings/events/sponsors/:mode/:sponsorid`,
        component: React.lazy(() => import('views/app-views/listings/events/sponsors/crud')),
    },
    {
        key: 'listing.events.categories',
        path: `${APP_PREFIX_PATH}/listings/events/categories`,
        component: React.lazy(() => import('views/app-views/listings/events/categories/')),
    },
    {
        key: 'listing.events.categories.add',
        path: `${APP_PREFIX_PATH}/listings/events/categories/:mode`,
        component: React.lazy(() => import('views/app-views/listings/events/categories/crud')),
    },
    {
        key: 'listing.events.categories.edit',
        path: `${APP_PREFIX_PATH}/listings/events/categories/:mode/:id`,
        component: React.lazy(() => import('views/app-views/listings/events/categories/crud')),
    },
    {
        key: 'test.imagefetch',
        path: `${APP_PREFIX_PATH}/image`,
        component: React.lazy(() => import('views/app-views/listings/giveback')),
    },
    {
        key: 'listings.givebacks',
        path: `${APP_PREFIX_PATH}/listings/giveback`,
        component: React.lazy(() => import('views/app-views/listings/giveback/list')),
    },
    {
        key: 'listing.givebacks.addnew',
        path: `${APP_PREFIX_PATH}/listings/giveback/:mode`,
        component: React.lazy(() => import('views/app-views/listings/giveback/list/crud')),
    },
    {
        key: 'listing.givebacks.edit',
        path: `${APP_PREFIX_PATH}/listings/giveback/:mode/:givebackid`,
        component: React.lazy(() => import('views/app-views/listings/giveback/list/crud')),
    },
    {
        key: 'events-listings.givebacks',
        path: `${APP_PREFIX_PATH}/listings/giveback/events`,
        component: React.lazy(() => import('views/app-views/listings/events/list/')),
    },
    {
        key: 'listing.givebacks.events',
        path: `${APP_PREFIX_PATH}/listings/giveback/events/:mode`,
        component: React.lazy(() => import('views/app-views/listings/giveback/list/crud')),
    },
    {
        key: 'listings.givebacks.categories',
        path: `${APP_PREFIX_PATH}/listings/giveback/categories`,
        component: React.lazy(() => import('views/app-views/listings/giveback/categories')),
    },
    {
        key: 'listing.givebacks.categories.addnew',
        path: `${APP_PREFIX_PATH}/listings/giveback/categories/:mode`,
        component: React.lazy(() => import('views/app-views/listings/giveback/categories/crud')),
    },
    {
        key: 'listing.givebacks.categories.edit',
        path: `${APP_PREFIX_PATH}/listings/giveback/categories/:mode/:id`,
        component: React.lazy(() => import('views/app-views/listings/giveback/categories/crud')),
    },
    {
        key: 'listings.givebacks.code',
        path: `${APP_PREFIX_PATH}/listings/giveback/code`,
        component: React.lazy(() => import('views/app-views/listings/giveback/code/')),
    },
    {
        key: 'listing.events.polls',
        path: `${APP_PREFIX_PATH}/listings/events/polls/`,
        component: React.lazy(() => import('views/app-views/listings/events/polls')),
    },
    {
        key: 'listing.events.polls.add',
        path: `${APP_PREFIX_PATH}/listings/events/polls/:mode/`,
        component: React.lazy(() => import('views/app-views/listings/events/polls/crud')),
    },
    {
        key: 'listing.events.polls.edit',
        path: `${APP_PREFIX_PATH}/listings/events/polls/:mode/:pollid`,
        component: React.lazy(() => import('views/app-views/listings/events/polls/crud')),
    },
    /*,
    {
        key: 'listing.givebacks.code.addnew',
        path: `${APP_PREFIX_PATH}/listings/giveback/code/:mode`,
        component: React.lazy(() => import('views/app-views/listings/giveback/code/crud')),
    },
    {
        key: 'listing.givebacks.code.edit',
        path: `${APP_PREFIX_PATH}/listings/giveback/code/:mode/:codeid`,
        component: React.lazy(() => import('views/app-views/listings/giveback/code/crud')),
    }*/,
    {
        key: 'automatic.events',
        path: `${APP_PREFIX_PATH}/automatic/events`,
        component: React.lazy(() => import('views/app-views/automatic/events')),
    },
    {
        key: 'automatic.events.business',
        path: `${APP_PREFIX_PATH}/automatic/events/:main_business_id`,
        component: React.lazy(() => import('views/app-views/automatic/events/getBusinessEvents')),
    },
    {
        key: 'automatic.events',
        path: `${APP_PREFIX_PATH}/automatic/givebackevents`,
        component: React.lazy(() => import('views/app-views/automatic/events/giveback')),
    },
    {
        key: 'automatic.events.business',
        path: `${APP_PREFIX_PATH}/automatic/givebackevents/:main_giveback_id`,
        component: React.lazy(() => import('views/app-views/automatic/events/getGivebackEvents')),
    },
    {
        key: 'notifications.app',
        path: `${APP_PREFIX_PATH}/notifications/app/`,
        component: React.lazy(() => import('views/notifications/app')),
    },
    {
        key: 'notifications.app.audience',
        path: `${APP_PREFIX_PATH}/notifications/app/audience`,
        component: React.lazy(() => import('views/notifications/app/audience')),
    },
    {
        key: 'notifications.app.audience.addnew',
        path: `${APP_PREFIX_PATH}/notifications/app/audience/:mode`,
        component: React.lazy(() => import('views/notifications/app/audience/crud')),
    },
    {
        key: 'notifications.app.audience.edit',
        path: `${APP_PREFIX_PATH}/notifications/app/audience/:mode/:id`,
        component: React.lazy(() => import('views/notifications/app/audience/crud')),
    },
    {
        key: 'notifications.app.messages',
        path: `${APP_PREFIX_PATH}/notifications/app/messages`,
        component: React.lazy(() => import('views/notifications/app/messages')),
    }
]

