import fetch from 'auth/FetchInterceptor'

const FaqService = {}
  

  FaqService.getfaq = function (data) {

    let url = `/api/admin/get-faq`;
	
    return fetch({
        url: url,
        method: 'get'
    });
} 


FaqService.getonefaq = function (id) {
	return fetch({
		url: '/api/admin/get-one-faq/'+id,
		method: 'get'
	})
}

FaqService.addfaq = function (data) {
	return fetch({
		url: '/api/admin/faq/add-faq',
		method: 'post',
		data: data,
	})
}

FaqService.updatefaq = function (data,Id) {
	
	return fetch({
		url: '/api/admin/edit-faq/'+Id,
		method: 'patch',
		data: data,
	})
}

FaqService.deletefaq = function (id) {
	return fetch({
		url: '/api/admin/delete-faq/'+id,
		method: 'delete'
	})
}

export default FaqService;