import fetch from 'auth/FetchInterceptor'

const UsersService = {}
  
UsersService.adduser = function (data) {

    let url = `/api/admin/add-user`;

    return fetch({
        url: url,
        method: 'post',
		data:data
    });
}

  UsersService.getusers = function (data) {

    let url = `/api/admin/get-all-users/?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;

    return fetch({
        url: url,
        method: 'get'
    });
}


UsersService.getoneuser = function (id) {
	return fetch({
		url: '/api/admin/get-user/'+id,
		method: 'get'
	})
}

UsersService.updateuser = function (data,userId) {

	return fetch({
		url: '/api/admin/edit-user/'+userId,
		method: 'patch',
		data: data,
	})
}

UsersService.deleteuser = function (id) {

	return fetch({
		url: '/api/admin/delete-user/'+id,
		method: 'delete'
	})
}

UsersService.interestedevents = function (data) {

    let url = `/api/admin/get-interested-events/?perPage=${data.perPage}&page=${data.page}&${data.query}`;

    return fetch({
        url: url,
        method: 'get'
    });
}

UsersService.followedgiveback = function (data) {

    let url = `/api/admin/get-followed-giveback/?perPage=${data.perPage}&page=${data.page}&${data.query}`;

    return fetch({
        url: url,
        method: 'get'
    });
}

export default UsersService;