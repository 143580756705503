import fetch from 'auth/FetchInterceptor'

const GivebackCategoriesService = {}
  

  GivebackCategoriesService.getgivebackcategories = function (data) {

    let url = `/api/admin/get-giveback-categories?perPage=${data.perPage}&page=${data.page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
} 

GivebackCategoriesService.getonegivebackcategory = function (id) {
	return fetch({
		url: '/api/admin/get-giveback-categorie/'+id,
		method: 'get'
	})
}

GivebackCategoriesService.addgivebackcategory = function (data) {
	return fetch({
		url: '/api/admin/giveback/add-giveback-categories',
		method: 'post',
		data: data,
	})
}

GivebackCategoriesService.updategivebackcategory = function (data,Id) {
	
	return fetch({
		url: '/api/admin/edit-giveback-categories/'+Id,
		method: 'patch',
		data: data,
	})
}

GivebackCategoriesService.deletegivebackcategory = function (id) {
	return fetch({
		url: '/api/admin/delete-giveback-categories/'+id,
		method: 'delete'
	})
}

export default GivebackCategoriesService;