import fetch from 'auth/FetchInterceptor'

const EventCategoriesService = {}
  

  EventCategoriesService.geteventcategories = function (data) {

    let url = `/api/admin/get-events-categories?perPage=${data.perPage}&page=${data.page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
} 

EventCategoriesService.getoneeventcategory = function (id) {
	return fetch({
		url: '/api/admin/get-events-categorie/'+id,
		method: 'get'
	})
}

EventCategoriesService.addeventcategory = function (data) {
	return fetch({
		url: '/api/admin/event/add-events-categories',
		method: 'post',
		data: data,
	})
}

EventCategoriesService.updateeventcategory = function (data,Id) {
	
	return fetch({
		url: '/api/admin/edit-events-categories/'+Id,
		method: 'patch',
		data: data,
	})
}

EventCategoriesService.deleteeventcategory = function (id) {
	return fetch({
		url: '/api/admin/delete-events-categories/'+id,
		method: 'delete'
	})
}

export default EventCategoriesService;