import fetch from 'auth/FetchInterceptor'

const SponsorsService = {}
  

  SponsorsService.getsponsors = function (data) {

    let url = `/api/admin/get-sponsor?perPage=${data.per_page}&page=${data.current_page}/?${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
}


SponsorsService.getonesponsor = function (id) {
	return fetch({
		url: '/api/admin/get-one-sponsor/'+id,
		method: 'get'
	})
}

SponsorsService.addsponsor = function (data) {
	return fetch({
		url: '/api/admin/sponsor/add-sponsor',
		method: 'post',
		headers: {'Content-Type' : 'application/x-www-form-urlencoded'},
		data: data,
	})
}

SponsorsService.updatesponsor = function (data,sponsorId) {
	
	return fetch({
		url: '/api/admin/edit-sponsor/'+sponsorId,
		method: 'patch',
		data: data,
	})
}

SponsorsService.deletesponsor = function (id) {
	return fetch({
		url: '/api/admin/delete-sponsor/'+id,
		method: 'delete'
	})
}

export default SponsorsService;