import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BusinessUsersService from 'services/business/BusinessUsersService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/business/users/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	last_deleted: null,
	editData: null
}

export const addBusinessUser = createAsyncThunk('businessusers/addbusinessuser',async (data, { rejectWithValue }) => {
	try {
		const response = await BusinessUsersService.addbusinessuser(data)
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAll = createAsyncThunk('businessusers/getbusinessusers',async (data, { rejectWithValue }) => {

	try {
		const response = await BusinessUsersService.getbusinessusers(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOne = createAsyncThunk('businessusers/getonebusinessuser',async (userId, { rejectWithValue }) => {
	try {
		const response = await BusinessUsersService.getonebusinessuser(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOne = createAsyncThunk('businessusers/updatebusinessuser',async ({data,userId}, { rejectWithValue }) => {
	try {
		const response = await BusinessUsersService.updatebusinessuser(data,userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOne = createAsyncThunk('businessusers/deletebusinessuser',async (userId, { rejectWithValue }) => {
	
	try {
		const response = await BusinessUsersService.deletebusinessuser(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const businessUsersSlice = createSlice({
	name: 'businessusers',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		cleareditData: (state) => {
			state.editData = null
		},
		resetPaginantion: (state) => {
			state.pagination = 1
			state.current_page=1
			state.per_page=10
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addBusinessUser.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addBusinessUser.fulfilled, (state, action) => {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false

			})
			.addCase(addBusinessUser.rejected, (state, action) => {
				/*message.error({
					content: action.payload.message,
					duration: 2,
				  });*/
				state.loading = false
			})

			//GET USERS LIST
			.addCase(getAll.pending, (state) => {
				state.editData = null
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage

				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				
			})
			.addCase(getAll.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//GET ONE USER
			.addCase(getOne.pending, (state) => {
				state.loading = true
			})
			.addCase(getOne.fulfilled, (state, action) => {
				state.loading = false
				state.editData = action.payload.data
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(getOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//EDIT USER
			//GET ONE USER
			.addCase(updateOne.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOne.fulfilled, (state, action) => {
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(updateOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//DELETE USER
			.addCase(deleteOne.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "User Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOne.fulfilled, (state, action) => {
				
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				});
				state.last_deleted = action.payload
			})
			.addCase(deleteOne.rejected, (state, action) => {
				
				message.error({
					content: action.payload.message,
					duration: 2,
				});
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	cleareditData,
	resetPaginantion
} = businessUsersSlice.actions

export default businessUsersSlice.reducer