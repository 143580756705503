import { combineReducers } from 'redux'
import theme from './slices/themeSlice'

import auth from './slices/authSlice'

import users from './slices/usersSlice'
import sales from './slices/salesSlice'
import wallets from './slices/walletSlice'

import businessusers from './businessSlices/businessUsersSlice'
import businesslistings from './businessSlices/businessListingSlice'
import businessstores from './businessSlices/businessStoresSlice'
import businesscategories from './businessSlices/businessCategoriesSlice'
import businessdeals from './businessSlices/businessDealsSlice'
import redeemed from './slices/redeemedSlice'

import eventslistings from './eventsSlices/eventsListingSlice'
import eventcategories from './eventsSlices/eventCategoriesSlice'
import sponsors from './eventsSlices/eventSponsorsSlice'
import tickets from './eventsSlices/eventsTicketSalesSlice'
import ticketRedeems from './eventsSlices/eventsTicketRemeedSlice'
import vendors from './eventsSlices/eventVendorsSlice'
import vendorcategories from './eventsSlices/eventVendorsCategoriesSlice'
import polls from './eventsSlices/eventsVoteSlice'

import givebacklistings from './givesbackSlice/givebackListingSlice'
import givebackcodes from './givesbackSlice/givebackCodesSlice'
import givebackcategories from './givesbackSlice/givebackCategoriesSlice'

import faqSlice from './admin/FaqSlice'

const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        users,
        sales,
        wallets,
        businessusers,
        businesslistings,
        businesscategories,
        businessdeals,
        businessstores,
        redeemed,
        eventslistings,
        eventcategories,
        sponsors,
        tickets,
        ticketRedeems,
        vendors,
        vendorcategories,
        polls,
        givebacklistings,
        givebackcodes,
        givebackcategories,
        faqSlice,
        ...asyncReducers,
    })
    return combinedReducer(state, action)
}
  
export default rootReducer
