import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import BusinessCategoriesService from 'services/business/BusinessCategoriesService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/business/categories/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1, 
	per_page:10,
	last_deleted: null,
	editData: null
}

export const addOne = createAsyncThunk('businesscategories/addbusinesscategory',async (data, { rejectWithValue }) => {
	try {
		const response = await BusinessCategoriesService.addbusinesscategory(data)
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAll = createAsyncThunk('businesscategories/getbusinesscategories',async (data, { rejectWithValue }) => {
	const {per_page,current_page,query} = data;
	try {
		const response = await BusinessCategoriesService.getbusinesscategories(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOne = createAsyncThunk('businesscategories/getonebusinesscategory',async (userId, { rejectWithValue }) => {
	try {
		const response = await BusinessCategoriesService.getonebusinesscategory(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOne = createAsyncThunk('businesscategories/updatebusinesscategory',async ({data,userId}, { rejectWithValue }) => {
	try {
		const response = await BusinessCategoriesService.updatebusinesscategory(data,userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOne = createAsyncThunk('businesscategories/deletebusinesscategory',async (userId, { rejectWithValue }) => {
	
	try {
		const response = await BusinessCategoriesService.deletebusinesscategory(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const businessCategoriesSlice = createSlice({
	name: 'businesscategories',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditUser: (state) => {
			state.editData = null
		}
	},
	extraReducers: (builder) => {
		builder

			//ADD CATEGORY
			.addCase(addOne.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addOne.fulfilled, (state, action) => {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})
			.addCase(addOne.rejected, (state, action) => {
				state.loading = false
			})

			//GET LIST
			.addCase(getAll.pending, (state) => {
				state.editData = null
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage

				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				
			})
			.addCase(getAll.rejected, (state, action) => {
				state.loading = false
			})


			//GET ONE
			.addCase(getOne.pending, (state) => {
				state.loading = true
			})
			.addCase(getOne.fulfilled, (state, action) => {
				state.loading = false
				state.editData = action.payload.data
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(getOne.rejected, (state, action) => {	
				state.loading = false
			})

			//UPDATE
			.addCase(updateOne.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOne.fulfilled, (state, action) => {
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(updateOne.rejected, (state, action) => {
				state.loading = false
			})


			//DELETE
			.addCase(deleteOne.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "User Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOne.fulfilled, (state, action) => {
				
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				});
				state.last_deleted = action.payload
			})
			.addCase(deleteOne.rejected, (state, action) => {
				
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditUser
} = businessCategoriesSlice.actions

export default businessCategoriesSlice.reducer