import fetch from 'auth/FetchInterceptor'

const BusinessStoreService = {}
  

  BusinessStoreService.getstores = function (data) {

    let url = `/api/admin/get-stores-outlets?perPage=${data.per_page}&page=${data.current_page}&${data.query}`;
	
    return fetch({
        url: url,
        method: 'get'
    });
}


BusinessStoreService.getonestore = function (id) {
	return fetch({
		url: '/api/admin/get-stores/'+id,
		method: 'get'
	})
}

BusinessStoreService.addstore = function (data) {
	return fetch({
		url: '/api/admin/stores/add-stores-outlets/',
		method: 'post',
		data: data,
	})
}

BusinessStoreService.updatestore = function (data,storeId) {
	
	return fetch({
		url: '/api/admin/edit-stores-outlets/'+storeId,
		method: 'patch',
		data: data,
	})
}

BusinessStoreService.deletestore = function (id) {
	return fetch({
		url: '/api/admin/delete-stores-outlets/'+id,
		method: 'delete'
	})
}

export default BusinessStoreService;