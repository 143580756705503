import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import GivebackListingService from 'services/giveback/GivebacksListingService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/listings/giveback/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	last_deleted: null,
	last_edited: null,
	editDataGiveback: null,
	unique_code: null
}

export const addOneGiveback = createAsyncThunk('givebacklistings/addgivebacklisting',async (data, { rejectWithValue }) => {
	try {
		const response = await GivebackListingService.addgivebacklisting(data)

		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAllGivebacks = createAsyncThunk('givebacklistings/getgivebacklistings',async (data, { rejectWithValue }) => {
	try {
		const response = await GivebackListingService.getgivebacklistings(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOneGiveback = createAsyncThunk('givebacklistings/getonegivebacklisting',async (givebackId, { rejectWithValue }) => {
	console.log('givebackId',givebackId)
	try {
		const response = await GivebackListingService.getonegivebacklisting(givebackId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOneGiveback = createAsyncThunk('givebacklistings/updategivebacklisting',async ({data,givebackId}, { rejectWithValue }) => {
	try {
		const response = await GivebackListingService.updategivebacklisting(data,givebackId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOneGiveback = createAsyncThunk('givebacklistings/deletegivebacklisting',async (givebackId, { rejectWithValue }) => {
	
	try {
		const response = await GivebackListingService.deletegivebacklisting(givebackId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const code = createAsyncThunk('givebacklistings/addgivebacklisting',async (data, { rejectWithValue }) => {
	
	try {
		const response = await GivebackListingService.code(data)

		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const givebackListingSlice = createSlice({
	name: 'givebacklistings',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditData: (state) => {
			state.editDataGiveback = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addOneGiveback.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addOneGiveback.fulfilled, (state, action) => {
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				 
				  state.last_edited = action.payload.data
				  
				}
				state.loading = false
			})
			.addCase(addOneGiveback.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET LIST
			.addCase(getAllGivebacks.pending, (state) => {
				state.editDataGiveback = null
				state.loading = true
			})
			.addCase(getAllGivebacks.fulfilled, (state, action) => {
				
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage
/*
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				*/
			})
			.addCase(getAllGivebacks.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//GET ONE
			.addCase(getOneGiveback.pending, (state) => {
				state.loading = true
			})
			.addCase(getOneGiveback.fulfilled, (state, action) => {
				state.loading = false
				state.editDataGiveback = action.payload.data
				state.unique_code = action.payload.data.unique_code
				console.log('action.payload',action.payload)
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				}
			})
			.addCase(getOneGiveback.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//EDIT 
			.addCase(updateOneGiveback.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOneGiveback.fulfilled, (state, action) => {
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });

				}
			})
			.addCase(updateOneGiveback.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//DELETE 
			.addCase(deleteOneGiveback.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "Giveback Listing Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOneGiveback.fulfilled, (state, action) => {
				
				state.loading = false
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.last_deleted = action.payload
			})
			.addCase(deleteOneGiveback.rejected, (state, action) => {
				
				message.error({
					content: action.payload.message,
					duration: 2,
				});
				state.loading = false
			})

	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData
} = givebackListingSlice.actions

export default givebackListingSlice.reducer