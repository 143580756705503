import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import RedeemsService from 'services/RedeemsService';
import { message } from 'antd';

export const initialState = {
	loading: false,
	redirect: '/dashboards/redeemed/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	editData: null,
}


export const getAllRedeems = createAsyncThunk('redeems/getredeems',async (data, { rejectWithValue }) => {

	try { 
		const response = await RedeemsService.getredeems(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOneRedeem = createAsyncThunk('redeems/getoneredeem',async (redeemId, { rejectWithValue }) => {
	try {
		const response = await RedeemsService.getoneredeem(redeemId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOneRedeem = createAsyncThunk('redeems/deleteoneredeem',async (Id, { rejectWithValue }) => {
	try {
		const response = await RedeemsService.deleteoneredeem(Id)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const redeemedSlice = createSlice({
	name: 'redeemed',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditData: (state) => {
			state.editData = null
		},
		resetPaginantion: (state) => {
			state.pagination = 1
			state.current_page=1
			state.per_page=10
		}
	},
	extraReducers: (builder) => {
		builder

			//GET REDEEMS LIST
			.addCase(getAllRedeems.pending, (state) => {
				state.editData = null
				state.loading = true
			})
			.addCase(getAllRedeems.fulfilled, (state, action) => {
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.perPage

				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				
			})
			.addCase(getAllRedeems.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//GET ONE USER
			.addCase(getOneRedeem.pending, (state) => {
				state.loading = true
			})
			.addCase(getOneRedeem.fulfilled, (state, action) => {
				state.loading = false
				state.editData = action.payload.data
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(getOneRedeem.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


			//DELETE ONE REDEEM
			.addCase(deleteOneRedeem.pending, (state) => {
				state.loading = true
			})
			.addCase(deleteOneRedeem.fulfilled, (state, action) => {
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(deleteOneRedeem.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})


	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData,
	resetPaginantion
} = redeemedSlice.actions

export default redeemedSlice.reducer