import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UsersService from 'services/UsersService';
import { message } from 'antd';

export const initialState = {
	me: null,
	loading: false,
	redirect: '/dashboards/users/',
	queryData: '',
	data: [],
	count: 0,
	pagination: 1,
	current_page:1,
	per_page:10,
	user_role: 'user',
	last_deleted: null,
	editData: null,
	interestedUserEvents : [],
	interestedUserEventsPaginations : {
		count: 0,
		pagination: 1,
		current_page:1,
		per_page:10,
	},
	followedGiveback : [],
	followedGivebackPaginations : {
		count: 0,
		pagination: 1,
		current_page:1,
		per_page:10,
	},
}

export const addOne = createAsyncThunk('users/adduser',async (data, { rejectWithValue }) => {
	try {
		const response = await UsersService.adduser(data)
		
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getAll = createAsyncThunk('users/getusers',async (data, { rejectWithValue }) => {
	const {per_page,current_page,query} = data;
	try {
		const response = await UsersService.getusers(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getOne = createAsyncThunk('users/getoneuser',async (userId, { rejectWithValue }) => {
	try {
		const response = await UsersService.getoneuser(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getProfil = createAsyncThunk('users/getprofil',async (userId, { rejectWithValue }) => {
	try {
		const response = await UsersService.getoneuser(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const updateOne = createAsyncThunk('users/updateuser',async ({data,userId}, { rejectWithValue }) => {
	try {
		const response = await UsersService.updateuser(data,userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const deleteOne = createAsyncThunk('users/deleteuser',async (userId, { rejectWithValue }) => {
	
	try {
		const response = await UsersService.deleteuser(userId)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const getInterestedEvents = createAsyncThunk('users/getinterestedevents',async (data, { rejectWithValue }) => {
console.log(data)
	try {
		const response = await UsersService.interestedevents(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})

export const getFollowedGiveback = createAsyncThunk('users/getfollowedgiveback',async (data, { rejectWithValue }) => {

	try {
		const response = await UsersService.followedgiveback(data)
		return response;
	} catch (err) {
		return rejectWithValue(err.response?.data?.message || 'Error')
	}
})


export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		showMessage: (state, action) => {
			message.success({
				content: action.payload,
				duration: 2,
			  });
		},
		setQueryData: (state, action) => {
			state.queryData = action.payload
		}
		,
		clearEditData: (state) => {
			state.editData = null
		},
		resetPaginantion: (state) => {
			state.pagination = 1
			state.current_page=1
			state.per_page=10
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(addOne.pending, (state) => {
				message.loading({
					content: "Add process started",
					duration: 1,
				});
				state.loading = true
			})
			.addCase(addOne.fulfilled, (state, action) => {
				if(action.payload.status === 0){
					message.error({
						content: action.payload.message,
						duration: 2,
					  });
				} else {
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
				}
				state.loading = false
			})

			.addCase(addOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET USERS LIST
			.addCase(getAll.pending, (state) => {
				state.editData = null
				state.loading = true
			})
			.addCase(getAll.fulfilled, (state, action) => {
				state.loading = false
				state.data = action.payload.data
				state.count = action.payload.count
				state.pagination = action.payload.paginationValue
				state.current_page = action.payload.page
				state.per_page = action.payload.per_page

				/*message.success({
					content: action.payload.message,
					duration: 2,
				  });*/
				
			})
			.addCase(getAll.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET ONE USER
			.addCase(getOne.pending, (state) => {
				state.loading = true
			})
			.addCase(getOne.fulfilled, (state, action) => {
				state.loading = false
				state.editData = action.payload.data
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(getOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET PROFIL
			.addCase(getProfil.pending, (state) => {
				state.loading = true
			})
			.addCase(getProfil.fulfilled, (state, action) => {
				state.loading = false
				state.me = action.payload.data
			})
			.addCase(getProfil.rejected, (state, action) => {
				state.loading = false
			})

			//EDIT USER
			//GET ONE USER
			.addCase(updateOne.pending, (state) => {
				state.loading = true
			})
			.addCase(updateOne.fulfilled, (state, action) => {
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				  });
			})
			.addCase(updateOne.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//DELETE USER
			.addCase(deleteOne.pending, (state) => {
				
				state.loading = true
				message.loading({
					content: "User Deletion Started",
					duration: 1,
				});
			})
			.addCase(deleteOne.fulfilled, (state, action) => {
				
				state.loading = false
				message.success({
					content: action.payload.message,
					duration: 2,
				});
				state.last_deleted = action.payload
			})
			.addCase(deleteOne.rejected, (state, action) => {
				
				message.error({
					content: action.payload.message,
					duration: 2,
				});
				state.loading = false
			})
			
			//GET USER INTERESTED EVENTS LIST
			.addCase(getInterestedEvents.pending, (state) => {
				state.loading = true
			})
			.addCase(getInterestedEvents.fulfilled, (state, action) => {
				state.loading = false
				state.interestedUserEvents = action.payload.data
				state.interestedUserEventsPaginations.count = action.payload.count
				state.interestedUserEventsPaginations.pagination = action.payload.paginationValue
				state.interestedUserEventsPaginations.current_page = action.payload.page
				state.interestedUserEventsPaginations.per_page = action.payload.perPage			
			})
			.addCase(getInterestedEvents.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})

			//GET USER Followed Giveback
			.addCase(getFollowedGiveback.pending, (state) => {
				state.loading = true
			})
			.addCase(getFollowedGiveback.fulfilled, (state, action) => {
				state.loading = false
				state.followedGiveback = action.payload.data
				state.followedGivebackPaginations.count = action.payload.count
				state.followedGivebackPaginations.pagination = action.payload.paginationValue
				state.followedGivebackPaginations.current_page = action.payload.page
				state.followedGivebackPaginations.per_page = action.payload.perPage			
			})
			.addCase(getFollowedGiveback.rejected, (state, action) => {
				message.error({
					content: action.payload.message,
					duration: 2,
				  });
				state.loading = false
			})
	},
})

export const { 
	showMessage,
	setQueryData,
	clearEditData,
	resetPaginantion
} = usersSlice.actions

export default usersSlice.reducer